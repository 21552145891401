import request from "@/utils/request";

//用户登录
export function login(data) {
    return request({
        url: "/front/user/login ",
        method: "post",
        data,
    });
}

//用户注册
export function register(data) {
    return request({
        url: "/front/user/register ",
        method: "post",
        data,
    });
}

//用户退出登录
export function logout(data) {
    return request({
        url: "/logout",
        method: "post",
        data,
    });
}


//获取当前用户信息
export function userInfo(query) {
    return request({
        url: "/front/user/getInfo",
        method: "get",
        params: query,
    });
}