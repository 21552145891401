import Vue from "vue";
import vueCookie from "vue-cookie";
import VueLazyLoad from "vue-lazyload";
import {
    Message,
    MessageBox,
    Pagination,
    DatePicker,
    TimePicker,
    Button,
    Upload,
    Select,
    Option,
    OptionGroup,
    Dialog,
    Cascader,
    Radio,
    RadioButton,
    RadioGroup,
    Table,
    TableColumn,
    Switch,
    Popover,
    Form,
    FormItem,
    Input,
    InputNumber,
    Checkbox,
    CheckboxGroup,
    Rate,
    Tag,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/table.css";
import Validate from "./filter/validate";
import VueFullPage from "vue-fullpage.js";
import VueAnimateNumber from "vue-animate-number";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Loading from "./utils/loading";
import "./components/js/common/plugin";

import "vant/lib/index.css";
import { getDicts } from "@/api/dict";
import { addUserCollection, cancelUserCollection, idsUserCollection } from "@/api/collection";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, download, handleTree } from "@/utils/ruoyi";
import htmlToPdf from '@/utils/htmlToPdf';
Vue.use(htmlToPdf);
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)
require('vue-video-player/src/custom-theme.css')

Vue.use(vueCookie);
Vue.use(VueLazyLoad, {
    loading: "/common/loading-svg/loading-bars.svg",
});
Vue.use(Pagination);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Upload);
Vue.use(Button);
Vue.use(Dialog);

Vue.use(Validate);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Cascader);
Vue.use(VueFullPage);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(VueAnimateNumber);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);
Vue.use(Switch);
Vue.use(Popover);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Rate);
Vue.use(Tag);

Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.addUserCollection = addUserCollection
Vue.prototype.cancelUserCollection = cancelUserCollection
Vue.prototype.idsUserCollection = idsUserCollection

// 路由登录拦截
router.beforeEach((to, from, next) => {
    // 要不要对meta.loginRequire属性做监控拦截
    if (
        to.matched.some(function(item) {
            return item.meta.loginRequire;
        })
    ) {
        let userToken = localStorage.getItem("userToken");
        if (Tool.isEmpty(userToken)) {
            //跳转登录页
        } else {
            next();
        }
    } else {
        next();
    }
    if (Tool.isMobile()) {
        if (to.path.indexOf("/pc") !== -1) {
            next({ path: to.fullPath.replace("/pc", "/mb") });
        }
    } else {
        if (to.path.indexOf("/mb") !== -1) {
            next({ path: to.fullPath.replace("/mb", "/pc") });
        }
    }
    let name = to.name;
    //pc端
    if (name.indexOf("pc-index") != -1) {
        store.dispatch("saveHeaderFlag", 1);
        store.dispatch("saveFooterFlag", "home");
    }
    if (name.indexOf("pc-evaluation") != -1) {
        store.dispatch("saveHeaderFlag", 2);
        store.dispatch("saveFooterFlag", "evaluation");
    }
    if (name.indexOf("pc-occupation-evaluation") != -1) {
        store.dispatch("saveHeaderFlag", 3);
        store.dispatch("saveFooterFlag", "evaluation");
    }
    if (name.indexOf("pc-doctor") != -1) {
        store.dispatch("saveHeaderFlag", 4);
        store.dispatch("saveFooterFlag", "doctor");
    }
    if (name.indexOf("pc-school") != -1) {
        store.dispatch("saveHeaderFlag", 5);
        store.dispatch("saveFooterFlag", "course");
    }
    if (name.indexOf("pc-message-advice") != -1) {
        store.dispatch("saveHeaderFlag", 6);
    }
    if (name.indexOf("pc-center") != -1) {
        store.dispatch("saveHeaderFlag", 7);
        store.dispatch("saveFooterFlag", "my");
    }
    //手机端
    if (name.indexOf("mb-index") != -1) {
        store.dispatch("saveHeaderFlag", 1);
        store.dispatch("saveFooterFlag", "home");
    }
    if (name.indexOf("mb-doctor") != -1) {
        store.dispatch("saveHeaderFlag", 4);
        store.dispatch("saveFooterFlag", "doctor");
    }
    if (name.indexOf("mb-evaluation") != -1) {
        store.dispatch("saveHeaderFlag", 2);
        store.dispatch("saveFooterFlag", "evaluation");
    }
    if (name.indexOf("mb-course") != -1) {
        store.dispatch("saveHeaderFlag", 5);
        store.dispatch("saveFooterFlag", "course");
    }
    if (name.indexOf("mb-my") != -1) {
        store.dispatch("saveHeaderFlag", 7);
        store.dispatch("saveFooterFlag", "my");
    }
    if (name.indexOf("mb-index") != -1 || name.indexOf("mb-evaluation-list") != -1 || name.indexOf("mb-doctor-list") != -1 || name.indexOf("mb-course-list") != -1 || name === "mb-my") {
        store.dispatch("saveFooterShow", true);
    } else {
        store.dispatch("saveFooterShow", false);
    }
});

Vue.prototype.$message = Message;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.config.productionTip = false;

window.APPLICATION = new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");