import Vue from "vue";
import Router from "vue-router";
//pc
import PcIndex from "./views/pc/index/index.vue";

//mobile
import MbIndex from "./views/mobile/index/index.vue";

Vue.use(Router);

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

//修改原型对象中的replace方法
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

const redirectUrl = Tool.isMobile() ? "mb/index" : "pc/index";

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0,
        };
    },
    routes: [{
            path: "/",
            redirect: redirectUrl,
        },
        {
            path: "*",
            redirect: redirectUrl,
        },
        {
            path: "/pc-login",
            name: "pc-login",
            component: () =>
                import ("./views/pc/login/login.vue"),
        },
        {
            path: "/pc-register",
            name: "pc-register",
            component: () =>
                import ("./views/pc/login/register.vue"),
        },
        {
            path: "/mb-login",
            name: "mb-login",
            component: () =>
                import ("./views/mobile/login/login.vue"),
        },
        {
            path: "/mb-register",
            name: "mb-register",
            component: () =>
                import ("./views/mobile/login/register.vue"),
        },
        {
            path: "/large/screen",
            name: "pc-large-screen",
            component: () =>
                import ("./views/pc/screen/largeScreen.vue"),
        },
        {
            path: "/pc",
            name: "pc-business",
            component: () =>
                import ("./views/pc/business.vue"),
            children: [{
                    path: "index",
                    name: "pc-index",
                    component: PcIndex,
                },
                {
                    path: "doctor-list",
                    name: "pc-doctor-list",
                    component: () =>
                        import ("./views/pc/doctor/doctorList.vue"),
                }, {
                    path: "doctor/detail",
                    name: "pc-doctor-detail",
                    component: () =>
                        import ("./views/pc/doctor/doctorDetail.vue"),
                },
                {
                    path: "evaluation-list",
                    name: "pc-evaluation-list",
                    component: () =>
                        import ("./views/pc/evaluation/evaluationList.vue"),
                },
                {
                    path: "occupation-evaluation-list",
                    name: "pc-occupation-evaluation-list",
                    component: () =>
                        import ("./views/pc/occupation/evaluationList.vue"),
                },
                {
                    path: "evaluation/detail",
                    name: "pc-evaluation-detail",
                    component: () =>
                        import ("./views/pc/evaluation/evaluationDetail.vue"),
                },
                {
                    path: "evaluation/page",
                    name: "pc-evaluation-page",
                    component: () =>
                        import ("./views/pc/evaluation/evaluationPage.vue"),
                }, {
                    path: "evaluation/analyse",
                    name: "pc-evaluation-analyse",
                    component: () =>
                        import ("./views/pc/evaluation/evaluationAnalyse.vue"),
                },
                {
                    path: "course-list",
                    name: "pc-school-guidance-list",
                    component: () =>
                        import ("./views/pc/guidance/courseList.vue"),
                }, {
                    path: "course-detail",
                    name: "pc-school-course-detail",
                    component: () =>
                        import ("./views/pc/guidance/courseDetail.vue"),
                }, {
                    path: "article-list",
                    name: "pc-school-article-list",
                    component: () =>
                        import ("./views/pc/guidance/articleList.vue"),
                }, {
                    path: "article-detail",
                    name: "pc-school-article-detail",
                    component: () =>
                        import ("./views/pc/guidance/articleDetail.vue"),
                }, {
                    path: "my/message/advice",
                    name: "pc-message-advice",
                    component: () =>
                        import ("./views/pc/advice/messageAdvice.vue"),
                },
                {
                    path: "my",
                    name: "pc-center",
                    component: () =>
                        import ("./views/pc/my/my.vue"),
                }, {
                    path: "my/evaluation",
                    name: "pc-my-evaluation",
                    component: () =>
                        import ("./views/pc/my/myEvaluation.vue"),
                }, {
                    path: "my/doctor",
                    name: "pc-my-doctor",
                    component: () =>
                        import ("./views/pc/my/myDoctor.vue"),
                }, {
                    path: "my/collection",
                    name: "pc-my-collection",
                    component: () =>
                        import ("./views/pc/my/myCollection.vue"),
                }, {
                    path: "about/us",
                    name: "pc-about-us",
                    component: () =>
                        import ("./views/pc/about/aboutUs.vue"),
                }
            ],
        },
        {
            path: "/mb",
            name: "mb-business",
            component: () =>
                import ("./views/mobile/business.vue"),
            children: [{
                path: "index",
                name: "mb-index",
                component: MbIndex,
            }, {
                path: "doctor/list",
                name: "mb-doctor-list",
                component: () =>
                    import ("./views/mobile/doctor/doctorList.vue"),
            }, {
                path: "doctor/detail",
                name: "mb-doctor-detail",
                component: () =>
                    import ("./views/mobile/doctor/doctorDetail.vue"),
            }, {
                path: "course/list",
                name: "mb-course-list",
                component: () =>
                    import ("./views/mobile/course/courseList.vue"),
            }, {
                path: "course/detail",
                name: "mb-course-detail",
                component: () =>
                    import ("./views/mobile/course/courseDetail.vue"),
            }, {
                path: "evaluation/list",
                name: "mb-evaluation-list",
                component: () =>
                    import ("./views/mobile/evaluation/evaluationList.vue"),
            }, {
                path: "evaluation/detail",
                name: "mb-evaluation-detail",
                component: () =>
                    import ("./views/mobile/evaluation/evaluationDetail.vue"),
            }, {
                path: "evaluation/page",
                name: "mb-evaluation-page",
                component: () =>
                    import ("./views/mobile/evaluation/evaluationPage.vue"),
            }, {
                path: "evaluation/analyse",
                name: "mb-evaluation-analyse",
                component: () =>
                    import ("./views/mobile/evaluation/evaluationAnalyse.vue"),
            }, {
                path: "my",
                name: "mb-my",
                component: () =>
                    import ("./views/mobile/my/my.vue"),
            }, {
                path: "my/course",
                name: "mb-my-course",
                component: () =>
                    import ("./views/mobile/my/myCourse.vue"),
            }, {
                path: "my/doctor",
                name: "mb-my-doctor",
                component: () =>
                    import ("./views/mobile/my/myDoctor.vue"),
            }, {
                path: "my/evaluation",
                name: "mb-my-evaluation",
                component: () =>
                    import ("./views/mobile/my/myEvaluation.vue"),
            }, {
                path: "my/collection",
                name: "mb-my-collection",
                component: () =>
                    import ("./views/mobile/my/myCollection.vue"),
            }, {
                path: "my/message/advice",
                name: "mb-my-message-advice",
                component: () =>
                    import ("./views/mobile/advice/messageAdvice.vue"),
            }, {
                path: "about/us",
                name: "mb-about-us",
                component: () =>
                    import ("./views/mobile/about/aboutUs.vue"),
            }],
        },
    ],
});