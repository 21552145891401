import {
    extend,
    ValidationObserver,
    ValidationProvider,
    localize,
} from "vee-validate";
import {
    required,
    email,
    min,
    max,
    between,
    numeric,
} from "vee-validate/dist/rules";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
export default {
    install(Vue) {
        Vue.component("ValidationObserver", ValidationObserver);
        Vue.component("ValidationProvider", ValidationProvider);
        extend("required", required);
        extend("email", email);
        extend("min", min);
        extend("max", max);
        extend("between", between);
        extend("numeric", numeric);
        extend("phone", {
            message: "请输入有效的手机号码",
            validate: (value) =>
                value.length === 11 && /^1[3456789]\d{9}$/.test(value),
        });
        extend("salary", {
            params: ["target"],
            validate(value, { target }) {
                return value * 1000 > target * 1000;
            },
            message: "最高预期薪水要大于{_target_}",
        });
    },
};

localize({
    "zh-CN": {
        names: {
            name: "姓名",
        },
        messages: {
            ...zh_CN.messages,
            required: "请输入{_field_}",
            email: "请输入正确的邮箱格式",
            password: "密码必须是6到18位",
        },
    },
});

localize("zh-CN");