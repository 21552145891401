<template>
  <div>
    <!---banner-->
    <div class="grid-banner"></div>
    <!--中间部分-->
    <div class="container-main">
      <div class="evaluate-main">
        <div class="evaluate-info">
          <div class="evaluate-title">
            <span>为什么要做测评</span>
          </div>
          <div class="evaluate-conn">
            <div class="evaluate-list">
              <div class="icon-eval dingw"></div>
              <div class="gzq-text">更准确的定位</div>
              <div class="gzq-perho">
                正确的自我定位就是明白自己的价值点准确的认知自己，懂得定位，就可以学会以理性的态度追求更好的生存状态，这样才能把命运的主动权握在自己手中。
              </div>
            </div>
            <div class="evaluate-list">
              <div class="icon-eval piq"></div>
              <div class="gzq-text">更精准的匹配</div>
              <div class="gzq-perho">
                测评大数据正在改造在线招聘，精准匹配你的价值，亮化你的优点。
              </div>
            </div>
            <div class="evaluate-list">
              <div class="icon-eval fuw"></div>
              <div class="gzq-text">更专业的服务</div>
              <div class="gzq-perho">
                测评能够帮助职业经纪人更了解你的潜能，给予你量身定制的服务。，延长设备使用寿命。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="heart-main">
        <div class="heart-info">
          <div class="title-homesec clearfix">
            <div class="subtitle-homesec">
              <i class="icon-subtitle"></i>职业心理体检
            </div>
            <a href="#" class="checkmore">
              查看更多>>
              <i></i>
            </a>
          </div>
          <div class="box-assesslist">
            <ul class="clearfix">
              <li v-for="test in testList" :key="test.id">
                <a @click="testJump(test.tId)" class="item-assesslist">
                  <div class="pic-assesslist">
                    <img
                      :src="test.picture"
                      width="260"
                      height="160"
                      :alt="test.tName"
                    />
                    <div class="title-assesslist">
                      <div class="titcon-assesslist">
                        <p>{{ test.tName }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="itemcon-assesslist">
                    <div class="subtit-assesslist">
                      {{ test.recommendDescribe }}
                    </div>
                    <div class="viewnum-assesslist">
                      <i class="icon-view"></i>已测{{ test.frequency }}次
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="title-homesec">
            <div class="subtitle-homesec">
              <i class="icon-subtitle"></i>精选测评
            </div>
          </div>
          <div class="box-assesslist">
            <ul class="clearfix">
              <li v-for="test in testRecommendList" :key="test.id">
                <a @click="testJump(test.tId)" class="item-assesslist">
                  <div class="pic-assesslist">
                    <img
                      :src="test.picture"
                      width="260"
                      height="160"
                      :alt="test.tName"
                    />
                    <div class="title-assesslist">
                      <div class="titcon-assesslist">
                        <p>{{ test.tName }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="itemcon-assesslist">
                    <div class="subtit-assesslist">
                      {{ test.recommendDescribe }}
                    </div>

                    <div class="viewnum-assesslist">
                      <i class="icon-view"></i>已测{{ test.frequency }}次
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { testIndexRecommend, testTypeQuery } from "@/api/test";
export default {
  name: "pcIndex",
  data: function() {
    return {
      testList: [],
      testRecommendList: [],
    };
  },
  mounted: function() {
    let _this = this;
    _this.testIndexRecommendQuery();
    _this.testQuery();
  },

  methods: {
    testIndexRecommendQuery() {
      let _this = this;
      testIndexRecommend().then((res) => {
        if (res.data) {
          if (res.data.length >= 5) {
            _this.testRecommendList = res.data.slice(0, 4);
          } else {
            _this.testRecommendList = res.data;
          }
        }
      });
    },
    testQuery() {
      let _this = this;
      testTypeQuery({ type: "2" }).then((res) => {
        if (res.data) {
          if (res.data.length >= 5) {
            _this.testList = res.data.slice(0, 4);
          } else {
            _this.testList = res.data;
          }
        }
      });
    },
    testJump(tId) {
      let userToken = localStorage.getItem("userToken");
      let url = "/pc/evaluation?tId=" + tId;
      if (!userToken) {
        return this.$router.push({
          name: "pc-login",
          query: {
            url: url,
          },
        });
      }
      this.$router.push({
        name: "pc-evaluation-detail",
        query: {
          tId: tId,
        },
      });
    },
  },
};
</script>

<style scoped></style>
