<template>
  <div class="wrapper">
    <div class="home-warp">
      <div class="wraphead">
        <div class="home-info">
          <div class="home-logo fl">
            <img src="@public/mobile/images/icon/logo-pic01.png" />
          </div>
        </div>
        <div class="home-banner"></div>
      </div>
      <div class="home-banner"></div>
      <div class="home-mainbpe pb-150">
        <div class="mainbpe-nav">
          <ul>
            <li @click="jump('/mb/evaluation/list')" class="index-li">
              <i class="bpe-icon sypicf"></i>
              <p class="bpe-text">心理测评</p>
            </li>
            <li @click="jump('/mb/doctor/list')" class="index-li">
              <i class="bpe-icon sypics"></i>
              <p class="bpe-text">心理预约</p>
            </li>
            <li @click="tip" class="index-li">
              <i class="bpe-icon sykcs"></i>
              <p class="bpe-text">心理课堂</p>
            </li>
            <li @click="tip" class="index-li">
              <i class="bpe-icon sykc"></i>
              <p class="bpe-text">心理阅读</p>
            </li>
            <li class="index-li" @click="jumpMyItem('/mb/my/evaluation')">
              <i class="bpe-icon sypic"></i>
              <p class="bpe-text">我的测评</p>
            </li>
            <li class="index-li" @click="jumpMyItem('/mb/my/doctor')">
              <i class="bpe-icon sytj"></i>
              <p class="bpe-text">我的咨询</p>
            </li>
            <li class="index-li" @click="jumpMyItem('/mb/my/collection')">
              <i class="bpe-icon laxl"></i>
              <p class="bpe-text">我的收藏</p>
            </li>
            <li class="index-li" @click="jumpMyItem('/mb/my/message/advice')">
              <i class="bpe-icon syxx"></i>
              <p class="bpe-text">留言建议</p>
            </li>
          </ul>
        </div>
        <div class="mainbpe-banner" @click="toastMessage">
          <img src="@public/mobile/images/pingche-banner.png" />
        </div>
        <div class="xlty-info">
          <div class="ber-info">
            <div class="tenber-title">
              <i class="icon-title"></i>职业心理体检
            </div>
            <div class="tenber-conn">
              <ul>
                <li v-for="test in testList" :key="test.id">
                  <div class="zyxlty-pic fl">
                    <img :src="test.picture" />
                    <div class="pic-text">{{ test.tName }}</div>
                  </div>
                  <div class="zyxlty-conn">
                    <div class="zyxlty-text">{{ test.recommendDescribe }}</div>
                    <div class="zyxlty-viewnum">
                      <i class="icon-view"></i>
                      已测{{ test.frequency }}次
                    </div>
                    <div
                      class="jxcsls-enter-change"
                      @click="testJump(test.tId)"
                    >
                      立即测试
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="ber-info">
            <div class="tenber-title"><i class="icon-title"></i>精选测评</div>
            <div class="tenber-conn">
              <ul>
                <li v-for="test in testRecommendList" :key="test.id">
                  <div class="zyxlty-pic fl">
                    <img :src="test.picture" />
                    <div class="pic-text">{{ test.tName }}</div>
                  </div>
                  <div class="zyxlty-conn">
                    <div class="zyxlty-text">{{ test.recommendDescribe }}</div>
                    <div class="zyxlty-viewnum">
                      <i class="icon-view"></i>
                      已测{{ test.frequency }}次
                    </div>
                    <div
                      class="jxcsls-enter-change"
                      @click="testJump(test.tId)"
                    >
                      立即测试
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { testIndexRecommend, testTypeQuery } from "@/api/test";
import { Toast } from "vant";
export default {
  components: {},
  name: "mbIndex",
  data() {
    return {
      testList: [],
      testRecommendList: [],
    };
  },
  mounted() {
    let _this = this;
    _this.testIndexRecommendQuery();
    _this.testQuery();
  },
  methods: {
    testIndexRecommendQuery() {
      let _this = this;
      testIndexRecommend().then((res) => {
        _this.testRecommendList = res.data;
      });
    },
    testQuery() {
      let _this = this;
      testTypeQuery({ type: "2" }).then((res) => {
        _this.testList = res.data;
      });
    },
    testJump(tId) {
      let userToken = localStorage.getItem("userToken");
      let url = "/mb/evaluation?tId=" + tId;
      if (!userToken) {
        return this.$router.push({
          name: "mb-login",
          query: {
            url: url,
          },
        });
      }
      this.$router.push({
        name: "mb-evaluation-detail",
        query: {
          tId: tId,
        },
      });
    },
    jumpList(routerName) {
      let _this = this;
      _this.$router.push({
        name: routerName,
      });
    },
    jumpDoctor(param) {
      let _this = this;
      _this.$router.push({
        name: "mb-doctor-list",
        params: {
          tag: param,
        },
      });
    },
    jump(url) {
      let _this = this;
      _this.$router.push({
        path: url,
      });
    },
    jumpMyItem(url) {
      let userToken = localStorage.getItem("userToken");
      if (!userToken) {
        return this.$router.push({
          name: "mb-login",
          query: {
            url: url,
          },
        });
      }
      this.$router.push({
        path: url,
      });
    },
    tip() {
      Toast.fail("该模块为自定义模块，请联系管理员后开放！");
    },
    toastMessage() {
      Toast.success("请在pc端查看!");
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@public/mobile/css/fost-base.less";
@import "~@public/mobile/css/layout.less";
.index-li {
  width: 25% !important;
  margin-bottom: 0.2rem;
}
.jxcsls-enter-change {
  width: 1.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.5rem;
  background-color: #ffa615;
  background-image: linear-gradient(to right, #e5b464, #ffa615);
  font-size: 0.24rem;
  color: #fff;
  text-align: center;
  margin-top: 0.1rem;
  float: right;
}
</style>
