import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./action";
Vue.use(Vuex);

const state = {
  username: "", //登录用户名
  headerFlag: 1, //头部flag值
  footerFlag: "home", //手机端底部flag值
  token: "",
  footerShow: true
};
export default new Vuex.Store({
  state,
  mutations,
  actions,
});
