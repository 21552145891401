<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import storage from "@/storage/storage";
import { mapActions } from "vuex";
import { userInfo } from "@/api/user";
export default {
  name: "root",
  data() {
    return {
      screenWidth: "",
    };
  },
  methods: {
    ...mapActions(["saveHeaderFlag", "saveUserName"]),
    //获取当前用户信息
    getCurrentUserInfo() {
      //获取用户信息
      userInfo().then((res) => {
        //存入vuex中
        this.$store.dispatch("saveUserName", res.user.userName);
      });
    },
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    let headerFlag = storage.getItem("headerFlag");
    if (headerFlag) {
      this.$store.dispatch("saveHeaderFlag", headerFlag);
    }
    let footerFlag = storage.getItem("footerFlag");
    if (footerFlag) {
      this.$store.dispatch("saveFooterFlag", footerFlag);
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      storage.setItem("headerFlag", this.$store.state.headerFlag);
      storage.setItem("footerFlag", this.$store.state.footerFlag);
    });
  },
  mounted() {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
      this.getCurrentUserInfo();
    }
  },
};
</script>
