<template>
  <div v-if="visited">
    <div class="loader">
      <div class="outer"></div>
      <div class="middle"></div>
      <div class="inner"></div>
    </div>
    <div class="dialog-bj" style="display:block;"></div>
  </div>
</template>

<script>
export default {
  name: "pcLoading",
  data() {
    return {
      visited: false,
    };
  },
  methods: {
    //打开
    showLoading() {
      let _this = this;
      _this.visited = true;
    },
    //关闭
    closeLoading() {
      let _this = this;
      _this.visited = false;
    },
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 60;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #3cefff;
  border-right-color: #3cefff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 42px;
  height: 42px;
  margin-left: -21px;
  margin-top: -21px;
  animation: spin 2s linear infinite;
}

.middle {
  width: 25.2px;
  height: 25.2px;
  margin-left: -12.6px;
  margin-top: -12.6px;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 9.6px;
  height: 9.6px;
  margin-left: -4.8px;
  margin-top: -4.8px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
