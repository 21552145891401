import request from "@/utils/request";

export function userCollectionList(query) {
    return request({
        url: "/front/user/collection/list",
        method: "get",
        params: query,
    });
}

export function addUserCollection(data) {
    return request({
        url: "/front/user/collection/add",
        method: "post",
        data,
    });
}

export function cancelUserCollection(data) {
    return request({
        url: "/front/user/collection/cancel",
        method: "post",
        data,
    });
}

export function idsUserCollection(query) {
    return request({
        url: "/front/user/collection/ids",
        method: "get",
        params: query,
    });
}