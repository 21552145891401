import request from "@/utils/request";

//测试列表
export function testList(query) {
    return request({
        url: "/front/evaluation/test/list",
        method: "get",
        params: query,
    });
}
//测试详情
export function testDetail(query) {
    return request({
        url: "/front/evaluation/test/detail",
        method: "get",
        params: query,
    });
}

//首页推荐
export function testIndexRecommend(query) {
    return request({
        url: "/front/evaluation/test/index/recommend",
        method: "get",
        params: query,
    });
}
export function testTypeQuery(query) {
    return request({
        url: "/front/evaluation/test/type",
        method: "get",
        params: query,
    });
}