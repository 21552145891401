/**
 * Vuex-mutations
 */
export default {
  saveUserName(state, username) {
    state.username = username;
  },
  saveHeaderFlag(state, headerFlag) {
    state.headerFlag = headerFlag;
  },
  saveFooterFlag(state, footerFlag) {
    state.footerFlag = footerFlag;
  },
  saveToken(state, token) {
    state.token = token;
  },
  saveFooterShow(state, footerShow) {
    state.footerShow = footerShow;
  },
};
