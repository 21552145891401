import axios from "axios";
import { Message } from "element-ui";
import { Toast } from "vant";

// create an axios instance
const service = axios.create({
    withCredentials: true,
    baseURL: "/api",
    timeout: 10000,
});

const isMobile = Tool.isMobile();

/**
 * axios拦截器  前置
 */
service.interceptors.request.use(
    function(config) {
        window.APPLICATION.$loading.show();
        let userToken = localStorage.getItem("userToken");
        if (Tool.isNotEmpty(userToken) && userToken !== "null") {
            config.headers["X-Access-Token"] = "Bearer " + userToken;
        }
        config.headers["X-Requested-With"] = "XMLHttpRequest";
        return config;
    },
    (error) => {}
);

//后置
service.interceptors.response.use(
    function(response) {
        window.APPLICATION.$loading.close(); //关闭加载
        let res = response.data;
        if (res.code == 200) {
            return res;
        } else if (res.code == 403 || res.code == 401) {
            let userToken = localStorage.getItem("userToken");
            //若userToken有值  清除token数据
            if (userToken) {
                localStorage.removeItem("userToken");
            }
            let fullPath = window.APPLICATION.$route.fullPath;
            window.APPLICATION.$router.push({
                name: "mb-login",
                query: {
                    url: fullPath
                }
            });
            return Promise.reject(res);
        } else {
            if (isMobile) {
                Toast.fail(res.msg);
            } else {
                Message.warning(res.msg);
            }
            return Promise.reject(res);
        }
    },
    function axiosRetryInterceptor(error) {
        console.log(error);
        window.APPLICATION.$loading.close(); //关闭加载
        let config = error.config;
        // 如果错误为请求超时则拒绝请求,提示信息
        if (
            error.code == "ECONNABORTED" &&
            error.message.indexOf("timeout") != -1
        ) {
            if (isMobile) {
                Toast.fail("服务器连接超时");
            } else {
                Message.warning("服务器连接超时");
            }
            return Promise.reject(error);
        }
        let status = error.response.status;
        if (status == "404" && error.message.indexOf("404") != -1) {
            if (isMobile) {
                Toast.fail(error.message + " with:" + config.url);
            } else {
                Message.warning(error.message + " with:" + config.url);
            }

        }
        if (status == "500") {
            if (isMobile) {
                Toast.fail("服务器异常");
            } else {
                Message.warning("服务器异常");
            }

        }
    }
);

export default service;