/**
 * Vuex-actions
 */
export default {
  saveUserName(context, username) {
    context.commit("saveUserName", username);
  },
  saveHeaderFlag(context, headerFlag) {
    context.commit("saveHeaderFlag", headerFlag);
  },
  saveFooterFlag(context, footerFlag) {
    context.commit("saveFooterFlag", footerFlag);
  },
  saveToken(context, token) {
    context.commit("saveToken", token);
  },
  saveFooterShow(context, footerShow) {
    context.commit("saveFooterShow", footerShow);
  },
};
